/* eslint-disable no-empty */
/**
 * DOCUMENTAÇÃO DOS
 * Itens novos adicionados no inicio da lista
 * 'viewedProducts': '123_-_321' // não permitir duplicado - ultimo produto acessado, se existir na lista, deve ser movido a frente.
 * 'viewedProductsCategory: 'animals_and_plants_-_finance' // na duvida se deixo duplicado para sugerir mais items das categorias mais acessadas
 * 'viewedProductsFormats: 'ebooks_-_online_services' // mesma duvida do anterior
 * 'viewedProductsFirstTag: 'barbeiro_-_brigadeiro' // acreditamos que a primeira tag é a que mais define o produto e vamos usá-la para sugerir produtos para o usuario
 */

const LOCAL_STORAGE_QUOTA = 95;
const MAX_ITEMS_LIST = 60;
const SEPARATOR = '_-_';

/**
 * Validar se localStorage não esta cheio
 */
const validateLocalStorageQuota = async () => {
  try {
    if (navigator.storage && navigator.storage.estimate) {
      const quota = await navigator.storage.estimate();
      const percentageUsed = (quota.usage / quota.quota) * 100;

      return percentageUsed < LOCAL_STORAGE_QUOTA;
    }
    return true;
  } catch (err) {
    return false;
  }
};

/**
 * Adicionar item simples ao storage
 * @param {string} key
 * @param {string} item
 */
export const localStorageAddItem = (key : string, item : string) => {
  try {
    localStorage.setItem(key, item);
  } catch (err) {}
};

/**
 * Obter item do storage
 * @param {string} key
 */
export const localStorageGetItem = (key : string) => {
  try {
    return localStorage.getItem(key);
  } catch (err) {}

  return undefined;
};

/**
 * Obter valor do storage em array
 * @param {String} key
 */
export const getLocalStorageAsArray = (key : string, separator?: string) => {
  try {
    const storageValue = localStorageGetItem(key);
    if (storageValue) {
      return storageValue.split(separator || SEPARATOR);
    }
  } catch (err) {}

  return [];
};

/**
 * Retorna o primeiro item da lista
 * @param {string} key
 */
export const localStorageGetFirstItemOf = (key : string) => {
  return `${localStorageGetItem(key) || ''}`.split(SEPARATOR)?.[0];
};

/**
 * @param {int} productId
 * @example
 *  viewedProducts storage: '123_-_321_-_231_-_213'
 *  productId: 123
 *  return true
 */
const isProductSaved = (productId : number, productsSaved : string[]) => productsSaved.some(item => item === productId.toString());

export const hasDataSaved = (data : string) => {
  const viewedProducts = localStorageGetItem(data);
  const viewedProductsArray = viewedProducts ? viewedProducts.split(SEPARATOR) : null;

  if (viewedProductsArray
      && Array.isArray(viewedProductsArray)
      && viewedProductsArray.length > 0) {
    return viewedProductsArray;
  }

  return [];

};

/**
 * Garante a quantidade de itens em cada localStorage
 * @param {Array} arrayValue
 */
const removeLastPositionBiggerThanSizeLimit = (arrayValue : any[]) => {
  if (Array.isArray(arrayValue) && arrayValue.length > MAX_ITEMS_LIST) {
    arrayValue.splice(MAX_ITEMS_LIST);
  }

  return arrayValue;
};

/**
 * Adiciona ou move o item para a primeira posição da lista
 * @param {string} key - chave do storage
 * @param {string} item - item a ser adicionado/movido
 */
const moveItemToFirstPosition = (key : string, item : string) => {
  const value = localStorageGetItem(key);
  if (value) {
    let arrayValue = value.split(SEPARATOR);
    const indexItem = arrayValue.indexOf(item);

    if (indexItem !== -1) {
      arrayValue.splice(indexItem, 1);
    }

    arrayValue.unshift(item);
    arrayValue = removeLastPositionBiggerThanSizeLimit(arrayValue);
    const resultsValue = arrayValue.join(SEPARATOR);

    localStorageAddItem(key, resultsValue);

    return resultsValue;
  }

  localStorageAddItem(key, item);
  return item;
};

/**
 * Remove metade do array de produtos
 */
const removeOldestInfo = (key : string) => {
  try {
    const storageValue = localStorageGetItem(key);
    if (storageValue) {
      const values = storageValue.split(SEPARATOR);
      if (values.length > MAX_ITEMS_LIST * (LOCAL_STORAGE_QUOTA / 100)) {
        values.splice(Math.trunc((values.length / 2)));
        localStorageAddItem(key, values.join(SEPARATOR));
      }
    }
  } catch (err) {}
};

/**
 * Chama todos os keys do localStorage de produto e remove metade das informações de cadaMAX_ITEMS_LIST
 */
const removeOldestProductInformation = () => {
  removeOldestInfo('viewedProducts');
};

const normalizeCategoryAndFormat = (categoryOrFormat : string) => {
  return `${categoryOrFormat || ''}`
    .replace('category.', '')
    .replace('.name', '');
};
/**
 * Remove todos os objetos de produto do localstorage
 */
const clearLocalStorageProductData = () => {
  try {
    localStorage.removeItem('viewedProducts');
  } catch (err) {}
};

/**
 * Recebe um produto e adiciona as suas informações no localStorage
 * @param {object} product
 * @param {boolean} isRetry
 */
export const localStorageAddProductInformation = async (product : Product, isRetry : boolean) => {
  try {
    // validando se o produto foi enviado
    if (!product) {
      return false;
    }

    const hasQuotaAvailable = await validateLocalStorageQuota();

    if (hasQuotaAvailable) {
      // adicionar informações apenas se o produto não houver sido acessado por ultimo.
      const { productId, category } = product;
      const key = 'viewedProducts';

      const productsSaved = hasDataSaved(key);
      const isProduct = productId ? isProductSaved(parseInt(productId, 10), productsSaved) : true;

      if (!isProduct) {
        moveItemToFirstPosition('viewedProducts', productId);
      }

      if (category) {
        moveItemToFirstPosition(
          'viewedProductsCategory',
          `${normalizeCategoryAndFormat(category)}: ${productId}`
        );
      }
    } else if (!isRetry) {
      // 1a vez rodando se a quota estiver cheia
      removeOldestProductInformation();
      localStorageAddProductInformation(product, true);
    } else {
      // 2a vez rodando se a quota estiver cheia e todas as informações são removidas
      clearLocalStorageProductData();
    }
  } catch (err) {}

  return undefined;
};
