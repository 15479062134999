import classnames from 'classnames';
import { sendAnalyticsEvent } from 'utils/datalayer';
import SuggestionTag from './SuggestionTag';

function SuggestionsList(props) {
  const {
    suggestions,
    prefixUrl,
    vertical,
    light,
    disabled,
    route,
    scrollable,
    showSearchLTR = false,
    type,
    title,
    onSuggestionClick
  } = props;

  if (!suggestions || !Array.isArray(suggestions) || suggestions.length < 1) {
    return null;
  }

  if (!suggestions || !Array.isArray(suggestions) || suggestions.length < 1) {
    return null;
  }

  const onClickFire = () => {
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: 'Marketplace',
      event_action: 'Click:TagFire2023:CTA'
    });
  };

  return (
    <div
      className={classnames(
        'suggestions-list',
        `suggestions-list--${vertical ? 'vertical' : 'horizontal'}`,
        { 'suggestions-list--scrollable': scrollable }
      )}
    >
      <ul className="suggestions-list__content">
        {title && (
          <span className="suggestions-list__label">
            {title}
          </span>
        )}
        {suggestions.map(suggestion => {
          return (
            suggestion?.value && (
              <li
                className={classnames('suggestions-list__suggestion__link', { 'suggestions-list': suggestion?.selected })}
                key={`suggestion-${suggestion.key}`}
              >
                <SuggestionTag
                  text={`${suggestion?.value || ''}`}
                  term={`${suggestion?.term || ''}`}
                  category={prefixUrl}
                  light={light || false}
                  disabled={disabled}
                  selected={suggestion?.selected}
                  type={type || 'purple'}
                  route={route}
                  showSearchLTR={showSearchLTR}
                  onClick={() => {
                    onSuggestionClick(suggestion);
                  }}
                />
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
}

SuggestionsList.defaultProps = {
  onSuggestionClick: () => {}
};

export default (SuggestionsList);
