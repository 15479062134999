import Icons from '../../../Icons';

type Props = {
  showRating: boolean;
  rating: number;
  totalReviews?: number;
};

function Rating({ showRating, rating, totalReviews }: Props) {
  if (showRating && rating) {
    return (
      <div className="product-card__rating">
        <span>{rating?.toFixed(1)}</span>
        <Icons
          className="product-card__rating__star"
          iconName="star"
          prefix="fas"
        />
        {totalReviews && (
          <span className="product-card__rating__total">
            (
            {totalReviews}
            )
          </span>
        )}
      </div>
    );
  }

  return null;
}

export default Rating;
