import { useTranslation } from 'next-i18next';
import Icons from 'components/Icons';

export function DotButton({ selected, onClick }) {
  return (
    <button
      className={`embla__dot ${selected ? 'is-selected' : ''}`}
      type="button"
      aria-hidden="true"
      tabIndex={-1}
      onClick={onClick}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  const onClickCallback = () => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };
  return (
    <div
      className={`embla-arrow-buttons right ${className}`}
      style={{ ...style }}
      onClick={onClickCallback}
    >
      <Icons prefix="far" iconName="chevron-right" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  const onClickCallback = () => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <div
      className={`embla-arrow-buttons left ${className}`}
      style={{ ...style }}
      onClick={onClickCallback}
    >
      <Icons prefix="far" iconName="chevron-left" />
    </div>
  );
}

export function PrevButton({ enabled, onClick }) {
  const { t } = useTranslation();

  return (
    <button
      className="embla__button embla__button--prev"
      onClick={onClick}
      disabled={!enabled}
      tabIndex={-1}
      aria-label={t('search.prev_product')}
    >
      <PrevArrow />
    </button>
  );
}

export function NextButton({ enabled, onClick }) {
  const { t } = useTranslation();

  return (
    <button
      className="embla__button embla__button--next"
      onClick={onClick}
      disabled={!enabled}
      tabIndex={-1}
      aria-label={t('search.next_product')}
    >
      <NextArrow />
    </button>
  );
}
