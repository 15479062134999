import React, { useState } from 'react';

import Image from 'next/image';
import classnames from 'classnames';

import {
  loaderImage,
  generateSrcSet,
  loaderStaticImage
} from '../../../utils/image';

const productImageFallback = 's/static/images/placeholder/product.jpg';

type Props = {
  src: string;
  avatar: string;
  className: string;
  priority?: boolean;
  firstImagePriority?: boolean;
  quality?: number;
  campaignStrip?: any;
  alt: string;
  type: string;
  width?: number;
  height?: number;
  blurDataURL?: string;
  placeholder?: 'blur' | 'empty';
};

function ProductImage({
  src,
  avatar,
  className,
  priority,
  firstImagePriority = false,
  quality,
  campaignStrip,
  alt,
  type,
  width,
  height,
  blurDataURL,
  placeholder = 'empty'
}: Props) {
  const [lastImage, setLastImage] = useState('');
  const showStrip =
    campaignStrip && Object.keys(campaignStrip || {}).length !== 0;

  const onError = e => {
    if (lastImage !== e.currentTarget.src) {
      const srcImage =
        avatar ?? loaderStaticImage({ src: productImageFallback });
      setLastImage(e.currentTarget.src);
      e.currentTarget.src = srcImage;
      e.currentTarget.srcset = generateSrcSet({ src: srcImage });
    }
  };

  return (
    <div className={classnames('product-image-wrapper', type)}>
      <Image
        src={src || loaderStaticImage({ src: productImageFallback })}
        loader={loaderImage}
        className={classnames(
          'product-image',
          { 'product-image-with-strip': showStrip },
          className
        )}
        onError={onError}
        quality={quality || 50}
        priority={priority || firstImagePriority || false}
        alt={alt}
        role={alt ? null : 'presentation'}
        width={width || 138}
        height={height || 138}
        blurDataURL={blurDataURL || ''}
        placeholder={placeholder}
      />
      {/* {showStrip && (
        <div className='campaign-strip' style={{ backgroundImage: `url('${campaignStrip?.["background_image"]?.urlFinal}')` }}>
          <img className="campaign-strip__logo" src={campaignStrip?.logo_image?.urlFinal} />
          <img className="campaign-strip__image" src={campaignStrip?.main_image?.urlFinal} />
        </div>
      )} */}
    </div>
  );
}

export default React.memo(ProductImage);
