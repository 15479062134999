import { FILTER } from '../constants';

export const isBestRated = (showRating: boolean, rating: number) => {
  return showRating && rating >= 3.5 || false;
};

const isPaymentAccount = (payment: string) => {
  return payment === FILTER.PAYMENT.ACCOUNT;
};

export const isProductHighlighted = (typePayment) => {
  return typePayment?.some(payment => isPaymentAccount(payment));
};