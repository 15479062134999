import { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { isSeasonActive } from 'utils/campaigns';
import { productQueryString, campaign } from '../../../constants';
import { MarketplaceContext } from '../../core/Provider/MarketplaceProvider';
import { generateURLQueryString, mountMarketplaceProductSalesPage } from '../../../utils/url';

function ProductLink({
  children,
  slug,
  seasonSlug,
  language,
  className,
  producerReferenceCode,
  isSeasonProduct,
  fullLink,
  tReady,
  searchId,
  linkTitle,
  origin,
  ...props
}) {
  const { i18n } = useTranslation('common');

  if (slug) {
    const producerReferenceCodePath = producerReferenceCode
      ? `/${producerReferenceCode}`
      : '';
    const context = useContext(MarketplaceContext);
    const { query = {} } = context;
    const { src, offer, sck, hotfeature } = query;
    const queryString = {};

    if (Array.isArray(productQueryString.PRODUCT_MARKETPLACE)) {
      productQueryString.PRODUCT_MARKETPLACE.forEach(queryParam => {
        queryString[queryParam.key] = queryParam.value;
      });
    }

    const productQueryStringSeason = campaign[isSeasonProduct]?.productQueryString || productQueryString;

    if (isSeasonProduct && isSeasonActive(i18n.language, campaign[isSeasonProduct])) {
      if (Array.isArray(productQueryStringSeason.PRODUCT_SEASON)) {
        productQueryStringSeason.PRODUCT_SEASON.forEach(queryParam => {
          if (!queryString?.[queryParam.key]) {
            queryString[queryParam.key] = queryParam.value;
          }
        });
      }
    }

    if (props.customSck) {
      queryString.sck = props.customSck;
    }

    if (sck && !props.customSck) {
      queryString.sck = sck;
    }

    if (src) {
      queryString.src = src;
    }

    if (offer) {
      queryString.off = offer;
    }

    if (seasonSlug) {
      queryString.hotcampaign = seasonSlug;
    }

    if (searchId) {
      queryString.search = searchId;
    }

    if (hotfeature) {
      queryString.hotfeature = hotfeature;
    }

    if (origin) {
      queryString.origin = origin;
    }

    const productURL = mountMarketplaceProductSalesPage(language, slug, producerReferenceCodePath);
    const urlFinal = generateURLQueryString(productURL, queryString);

    return (
      <a
        href={fullLink || urlFinal}
        className={classnames('product-link', className)}
        aria-label={linkTitle}
        {...props}
      >
        {children}
      </a>
    );
  }
  return (
    <div className={classnames('product-link', className)} {...props}>
      {children}
    </div>
  );
}

export default ProductLink;
