/* eslint-disable @typescript-eslint/dot-notation */
import Link from 'next/link';
import { useContext } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';

import { MarketplaceContext } from '../core/Provider/MarketplaceProvider';
import { convertUrlCategories } from '../../utils/url';

import '@hotmart/cosmos/dist/tag/variations/blue.css';
import '@hotmart/cosmos/dist/tag/variations/purple.css';

function SuggestionTag(props) {
  const context = useContext(MarketplaceContext);
  const { t } = useTranslation();

  const { query: { sck, src } = {} } = context;
  const {
    text,
    prefixUrl,
    route,
    category,
    type,
    onClick,
    showBlackNovemberCategory,
    blackNovemberLabel,
    blackNovemberSlug,
    light,
    disabled,
    selected,
    term,
    showSearchLTR
  } = props;

  const params = {};

  if (showBlackNovemberCategory && blackNovemberSlug) {
    params.categories = blackNovemberSlug;
  } else if (prefixUrl) {
    params.slug = prefixUrl;
  } else if (category) {
    params.categories = category;
    params.initialSelectedCategory = category;
  }

  if (!showBlackNovemberCategory) {
    params.q = term || text;
  }

  if (sck) {
    params.sck = sck;
  }

  if (src) {
    params.src = src;
  }

  if (showSearchLTR) {
    params.variant = 1;
  }

  const Router = useRouter();
  const finalText = showBlackNovemberCategory ? blackNovemberLabel : text;
  const finalRoute = route ? convertUrlCategories(route, params) : false;

  const navigateTag = finalRoute => {
    onClick();
    Router.push(finalRoute);
  };

  const handleClickTest = () => {
    window['optimizely'] = window['optimizely'] || [];
    window['optimizely'].push({
      type: 'event',
      eventName: 'click-more-accessed-item',
      tags: {
        value: `${finalText}: ${t('sections.mosted_accessed')}`
      }
    });
  };

  return (
    <hot-tag
      class={classnames(
        `suggestions-list__tag hot-tag--${type}`,
        { 'hot-tag--active': !light || selected },
        { 'suggestions-list__tag--disabled': disabled }
      )}
    >
      <div className="suggestions-list__tag__body">
        {finalRoute && (
          <Link href={finalRoute}>
            <a
              rel="nofollow"
              onClick={handleClickTest}
              aria-label={`${finalText}: ${t('sections.mosted_accessed')}`}
            >
              {finalText}
            </a>
          </Link>
        )}

        {!finalRoute && (
          <a onClick={onClick && handleClickTest} rel="nofollow" tabIndex={-1}>
            {finalText}
          </a>
        )}
      </div>
    </hot-tag>
  );
}

SuggestionTag.defaultProps = {
  text: ''
};

export default SuggestionTag;
