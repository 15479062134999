import classnames from 'classnames';
import { BannerVariants } from 'utils/string';

type Props = {
  discountValue?: number;
  creator: string;
  bannerVariant?: BannerVariants;
};

function DiscountBanner({
  discountValue,
  creator = '',
  bannerVariant = BannerVariants.DEFAULT
}: Props) {
  const isTag = bannerVariant === BannerVariants.TAG;
  const isCreator = bannerVariant === BannerVariants.CREATOR_TAG;
  const discountPercentage = discountValue * 100;

  return (
    <div
      className={classnames('discount-banner _mb-3', {
        'tag-container': isTag,
        'creator-tag-container': isCreator,
        'discount-banner--creator': creator
      })}
    >
      <div className="discount-banner__content">
        <img
          className={classnames({
            'tag__logo': isTag,
            'creator-tag__logo': isCreator,
            'discount-banner__logo': !isTag && !isCreator
          })}
          src="static/images/black-november/logo.svg"
          alt="Black November Banner"
        />
      </div>
      <div
        className={classnames('discount-banner__value', {
          'right-border-radius tag__value': isTag,
          'bottom-right-border-radius tag__value': isCreator
        })}
      >
        {discountValue && <span>{`-${discountPercentage}%`}</span>}
        {creator && <span>{creator}</span>}
      </div>
    </div>
  );
}

export default DiscountBanner;
